<template>
  <div>
    <el-form>
      <el-row>
        <el-col :span="8">
          <el-form-item
            :label="(set.type || '资讯类型') + ':'"
            label-width="75px"
          >
            <el-cascader
              v-model="value10"
              size="mini"
              :options="options"
              :props="{ expandTrigger: 'hover' }"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="(set.from || '资讯来源') + ':'"
            label-width="75px"
          >
            <el-input
              v-model="addInformation.source"
              size="mini"
              style="width:100%"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="(set.time || '发布时间') + ':'"
            label-width="75px"
          >
            <el-date-picker
              v-model="addInformation.time"
              type="date"
              size="mini"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width:100%"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16">
          <el-form-item
            :label="(set.title || '资讯标题') + ':'"
            label-width="75px"
          >
            <el-input
              v-model="addInformation.theTitle"
              size="mini"
              style="width:100%"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="(set.num || '浏览量') + ':'" label-width="75px">
            <el-input
              v-model="addInformation.views"
              size="mini"
              style="width:100%"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="内容摘要:" label-width="75px">
            <el-input
              type="textarea"
              :autosize="{ minRows: 6.5, maxRows: 6.5 }"
              placeholder="请输入内容"
              v-model="addInformation.content"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="封面照片:" label-width="75px">
            <el-upload
              action="https://upload-z2.qiniup.com"
              :data="picData"
              ref="upload"
              list-type="picture-card"
              :before-upload="beforeUpload"
              :on-success="handleAvatarSuccess"
              :on-error="handleError"
              :on-preview="handlePictureCardPreview"
              :before-remove="beforeRemove"
              :on-remove="handleRemove"
              :limit="1"
              :file-list="pic"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="edit_container">
      <quill-editor
        class="ql-editor"
        v-model="content"
        ref="myQuillEditor"
        :options="editorOption"
      ></quill-editor>
      <el-upload
        class="avatar-uploader"
        :data="picData"
        action="https://upload-z2.qiniup.com"
        :before-upload="beforeUpload"
        :on-success="success"
        :on-error="handleError"
        :show-file-list="false"
        style="display: none">
        <i class="el-icon-plus avatar-uploader-icon" id="imgInput"></i>
      </el-upload><!--富文本图片上传-->
    </div>
    <div slot="footer">
      <el-button @click="tianjiazixun = false">取 消</el-button>
      <el-button type="primary" v-if="saveState" @click="tianjiazixunPut"
        >保 存</el-button
      >
      <el-button type="primary" v-else @click="tianjiazixunSave"
        >保 存</el-button
      >
    </div>
  </div>
</template>

<script>
import { dateFtt } from "../../../assets/utils";
import * as Quill from "quill";
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
Quill.register("modules/resizeImage ", resizeImage);
export default {
  props: {
    set: {
      type: Object,
      default() {
        return {};
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      value10: "",
      id: '',
      picData: {
        //上传图片携带的参数
        token: "",
      },
      pic: [],
      content: "",
      saveState: false,
      addInformation: {
        type: "",
        type2: "",
        source: "",
        time: dateFtt("yyyy-MM-dd", new Date()),
        theTitle: "",
        views: 0,
        content: "",
        photo: "",
      },
      editorOption: {
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize"],
          },
          toolbar: [
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"], //链接与图片
            [{ color: [] }, { background: [] }], //颜色和背景色
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
          ],
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false,
          },
        },
      },
    };
  },
  created() {
    this.beforeUpload()
  },
  mounted() {
    this.show()
  },
  methods: {
    cleanDialogAdd() {
      //添加资讯弹框清空
      this.addInformation = {
        type: "",
        type2: "",
        source: "",
        time: dateFtt("yyyy-MM-dd", new Date()),
        theTitle: "",
        views: 0,
        content: "",
        photo: "",
      };
      this.value10 = "";
      this.content = "";
      this.pic = [];
      this.dataGet();
      this.rowData = "";
    },
    handleCurrentChange(cursor) {
      this.cursor = cursor;
      this.dataGet();
    },
    handleSizeChange(limit) {
      this.limit = limit;
      this.dataGet();
    },
    // 准备富文本编辑器
    onEditorReady(editor) {},
    show() {
      this.$nextTick(() => {
        this.$refs.myQuillEditor.quill
          .getModule("toolbar")
          .addHandler("image", this.imgHandler);
      });
    },
    success(res, file, fileList) {
      //富文本图片上传成功
      //图片的远程路径
      let value = "https://pic-gongkai.fangzhizun.com/" + res.key;
      // 将图片添加到富文本内容区域
      this.addRange = this.$refs.myQuillEditor.quill.getSelection();
      // 调用编辑器的 insertEmbed 方法，插入URL
      this.$refs.myQuillEditor.quill.insertEmbed(
        this.addRange !== null ? this.addRange.index : 0,
        "image",
        value,
        Quill.sources.USER
      );
    },
    imgHandler(state) {
      // 点击图片ICON触发事件
      this.addRange = this.$refs.myQuillEditor.quill.getSelection();
      if (state) {
        // 选择并上传一张图片
        let fileInput = document.getElementById("imgInput");
        fileInput.click(); // 加一个触发事件
      }
    },
    saveHtml: function(event) {
      alert(this.content);
    },
    // tianjiazixunGetPhotos(random){
    //   this.$axios({
    //     method: 'get',
    //     url: '/common1/photos',
    //     params: {
    //       photoRandom: random,
    //       photoTableType: '资讯'
    //     }
    //   }).then(res => {
    //     console.log(res)
    //   }).catch(err => {
    //     this.isError(err,this)
    //   })
    // },
    // tianjiazixunPhotos(){//上传图片到本地库
    //   let random = Math.ceil(Math.random()*1000).toString()
    //   let photoList = [
    //     {
    //       photoBucket: "公有",
    //       photoMimeType: "图片",
    //       photoRandom: random,
    //       photoStatus: "正常",
    //       photoTableType: "资讯",
    //       photoUrl: this.addInformation.photo
    //     }
    //   ]
    //   this.$axios({
    //     method: 'post',
    //     url: '/common1/photos',
    //     data: JSON.stringify(photoList)
    //   }).then(res => {
    //     this.tianjiazixunGetPhotos(random)
    //   }).catch(err => {
    //     this.isError(err,this)
    //   })
    // },
    close() {
      this.$emit("close");
    },
    tianjiazixunSave() {
      (this.loading = true),
        this.$axios({
          method: "post",
          url: "/common1/articles",
          data: {
            jatId: this.value10[1],
            jaSource: this.addInformation.source,
            jaPublishTime: this.addInformation.time,
            jaTitle: this.addInformation.theTitle,
            jaHitCount: this.addInformation.views,
            jaCoverPicture: JSON.stringify([
              { url: this.addInformation.photo },
            ]),
            jaSketch: this.addInformation.content,
            jaContent: this.content,
          },
        })
          .then((res) => {
            this.$message.success("添加成功");
            // this.tianjiazixunPhotos()
            this.dataGet();
            this.loading = false;
            this.close();
          })
          .catch((err) => {
            this.loading = false;
            this.close();
            this.isError(err, this);
          });
    },
    //获取token
    getToken() {
      return this.$axios({
        method: "get",
        url: "/common1/photos/token",
        params: {
          bucket: "公有",
        },
      })
        .then((res) => {
          return res.data.result;
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    async beforeUpload() {
      //上传完文件前获取token
      try {
        let getToken = await this.getToken();
        this.picData.token = getToken;
      } catch (err) {
        console.log(err);
      }
    },
    edit(id) {
      this.saveState = true
      this.id = id
      this.$axios({
        method: "get",
        url: `/common1/articles/${id}`,
      })
        .then((res) => {
          let assignment = res.data.result;
          if (res.data.result.jaCoverPicture[0] != undefined) {
            res.data.result.jaCoverPicture = JSON.parse(
              res.data.result.jaCoverPicture
            )[0].url;
          }
          if (res.data.result.jaCoverPicture != "") {
            this.pic = [{ name: "pic.png", url: assignment.jaCoverPicture }];
          }
          this.value10 = assignment.jatId;
          this.addInformation.source = assignment.jaSource;
          this.addInformation.time = assignment.jaPublishTime;
          this.addInformation.theTitle = assignment.jaTitle;
          this.addInformation.views = assignment.jaHitCount;
          this.addInformation.content = assignment.jaSketch;
          this.content = assignment.jaContent;
          this.addInformation.photo = assignment.jaCoverPicture;
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    tianjiazixunPut() {
      //修改保存
      console.log(this.addInformation.photo);
      let value11 = 0;
      if (this.value10[1] == undefined) {
        value11 = this.value10;
      } else {
        value11 = this.value10[1];
      }
      if (this.addInformation.photo == "") {
        this.photoModify = "";
      } else {
        this.photoModify = JSON.stringify([{ url: this.addInformation.photo }]);
      }
      this.$axios({
        method: "put",
        url: `/common1/articles/${this.id}`,
        data: {
          jatId: value11,
          jaSource: this.addInformation.source,
          jaPublishTime: this.addInformation.time,
          jaTitle: this.addInformation.theTitle,
          jaHitCount: this.addInformation.views,
          jaCoverPicture: this.photoModify,
          jaSketch: this.addInformation.content,
          jaContent: this.content,
        },
      })
        .then((res) => {
          this.$message.success("修改成功");
          this.close()
          this.dataGet();
        })
        .catch((err) => {
          this.close()
          this.isError(err, this);
        });
    },
    handleAvatarSuccess(res, file, fileList) {
      //上传成功后在图片框显示图片
      this.dialogImageUrl = "https://pic-gongkai.fangzhizun.com/" + res.key;
      file.url = this.dialogImageUrl;
      this.addInformation.photo = file.url;
      this.pic = fileList;
    },
    handleError(res) {
      //显示错误
      console.log(res);
    },
    handlePictureCardPreview(file) {
      //查看某张图片的原图
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeRemove(file) {
      //删除前
      return this.$confirm(`确定移除？`);
    },
    handleRemove(file, fileList) {
      //删除后
      this.pic = fileList;
      this.addInformation.photo = "";
    },
  },
};
</script>

<style lang="scss" scoped>
>>> .ql-editor {
  height: 150px;
}
</style>
