<template>
  <div class="Part">
    <div class='input'>
      <el-row>
        <!-- authe.Hotel_CorporateStyle_News_AddNews -->
        <el-button type="primary" 
        @click="tianjiazixunButton"
        size="mini" 
        v-hasPermi="'CorporateStyle_News_AddNews'"
        >
        添加资讯</el-button>&nbsp;
          <el-dialog :title="this.title" :visible.sync="tianjiazixun" v-loading="loading" @close="cleanDialogAdd" :close-on-click-modal="false" width="800px" destroy-on-close>
            <!-- <el-form>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="资讯类型:" label-width="75px">
                    <el-cascader
                      v-model="value10"
                      size="mini"
                      :options="options"
                      :props="{ expandTrigger: 'hover' }">
                    </el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="资讯来源:" label-width="75px">
                    <el-input v-model="addInformation.source" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="发布时间:" label-width="75px">
                    <el-date-picker
                      v-model="addInformation.time"
                      type="date"
                      size="mini"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      style="width:100%"
                      placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="16">
                  <el-form-item label="资讯标题:" label-width="75px">
                    <el-input v-model="addInformation.theTitle" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="浏览量:" label-width="75px">
                    <el-input v-model="addInformation.views" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="内容摘要:" label-width="75px">
                    <el-input
                            type="textarea"
                            :autosize="{ minRows: 6.5, maxRows: 6.5}"
                            placeholder="请输入内容"
                            v-model="addInformation.content">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="封面照片:" label-width="75px">
                    <el-upload
                            action="https://upload-z2.qiniup.com"
                            :data="picData"
                            ref="upload"
                            list-type="picture-card"
                            :before-upload="beforeUpload"
                            :on-success="handleAvatarSuccess"
                            :on-error="handleError"
                            :on-preview="handlePictureCardPreview"
                            :before-remove="beforeRemove"
                            :on-remove="handleRemove"
                            :limit="1"
                            :file-list="pic">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="edit_container">
              <quill-editor class="ql-editor" v-model="content" ref="myQuillEditor" :options="editorOption"></quill-editor>
            </div>
            <div slot="footer">
              <el-button @click="tianjiazixun = false">取 消</el-button>
              <el-button type="primary" v-if="saveState" @click="tianjiazixunPut">保 存</el-button>
              <el-button type="primary" v-else @click="tianjiazixunSave">保 存</el-button>
            </div> -->
            <v-message ref="message" :options="options" @close="tianjiazixun=false"></v-message>
          </el-dialog>
          <!--  查看资讯详情弹出框-->
          <el-dialog :visible.sync="checkInformation" v-loading="loading" @close="cleanDialogAdd" :close-on-click-modal="false">
            <el-form>
              <el-row class="honorTitle">
                <p>{{addInformation.theTitle}}</p>
                <span>类型: {{value10}}</span><span>来源: {{addInformation.source}}</span><span>{{addInformation.time}}</span><span>{{addInformation.views}}次浏览</span>
                <hr style="border:none;background-color: #DCDCDC;height: 1px;margin-top: 12px">
              </el-row>
              <el-row>
                <p style="margin: 0 0 10px 0">内容摘要:</p>
                <p class="contentAbstract">{{addInformation.content}}</p>
              </el-row>
              <quill-show :content="content"></quill-show>
              <!-- <el-row class="show-quill">
                <div class="ql-container ql-snow">
                  <div class="ql-editor" v-html="content">
                  </div>
                </div>
              </el-row> -->
            </el-form>
          </el-dialog>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
          <!-- <el-upload
            :data="picData"
            class="avatar-uploader"
            action="https://upload-z2.qiniup.com"
            :before-upload="beforeUpload"
            :on-success="success"
            :on-error="handleError"
            :show-file-list="false"
            style="display: none">
            <i class="el-icon-plus avatar-uploader-icon" id="imgInput"></i>
          </el-upload> -->
          <!-- 富文本图片上传 -->
        <el-button type="primary" @click="xiugaizixunButton" size="mini" v-if="authe.Hotel_CorporateStyle_News_ModifyNews">修改资讯</el-button>&nbsp;
        <el-button type="primary" @click="consultingDelete" size="mini" v-if="authe.Hotel_CorporateStyle_News_DeleteNews">删除资讯</el-button>&nbsp;
        <el-button type="primary" @click="zixunleixinButton" size="mini" v-if="authe.Hotel_CorporateStyle_News_TypeSetting">类型设置</el-button>&nbsp;
          <el-dialog title="咨询类型" :visible.sync="zixunleixin" v-loading="loading" :close-on-click-modal="false" width="600px">
            <el-button @click="zixunleixinAddClick" icon="el-icon-edit" size="mini">添加资讯类型</el-button>
            <el-button @click="zixunleixinAlter" icon="el-icon-edit" size="mini">修改资讯类型</el-button>
            <el-button @click="deleteIns" icon="el-icon-edit" size="mini">删除资讯类型</el-button>
            <el-table
                    :data="informationList"
                    height="400px"
                    highlight-current-row
                    @current-change="currentInfData"
                    @row-dblclick="dbClickInf"
                    style="width:100%;"><!--数据表-->
              <el-table-column prop="jatArticleCategory" label="资讯类型" min-width="100px"></el-table-column>
              <el-table-column prop="jatArticleType" label="资讯类别" min-width="100px"></el-table-column>
            </el-table>
          </el-dialog>
          <el-dialog :title="zixunTitle" :visible.sync="zixunleixinAdd" v-loading="loading" @close="cleanDialogAddIns" :close-on-click-modal="false" width="400px">
            <el-form>
              <el-form-item label="资讯类型:" label-width="75px">
                <el-input v-model="addType" size="mini"></el-input>
              </el-form-item>
              <el-form-item label="资讯类别:" label-width="75px">
                <el-input v-model="addCategory" size="mini"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer">
              <el-button @click="zixunleixinAdd = false">取 消</el-button>
              <el-button type="primary" v-if="ifPutIns" @click="zixunleixinAddPut">保 存</el-button>
              <el-button type="primary" v-else @click="zixunleixinAddSave">保 存</el-button>
            </div>
          </el-dialog>
          <!-- <v-message></v-message> -->
        <el-button type="primary" @click="recycleButton" size="mini" v-if="authe.Hotel_CorporateStyle_News_RecycleBin">回收站</el-button>&nbsp;
          <el-dialog title="回收站" :visible.sync="recycle" v-loading="loading" :close-on-click-modal="false" width="900px">
            <el-button @click="reduction" icon="el-icon-edit" size="mini">还原</el-button>
            <!-- 内容表格 -->
            <div>
              <el-table
                      v-loading="loading"
                      :data="dataList2"
                      highlight-current-row
                      @select="deleteBatch"
                      @select-all="deleteBatchAll"
                      height='500px'
                      style="width:100%;">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="jatArticleCategory" label="资讯类型" min-width="100px"></el-table-column>
                <el-table-column prop="jaTitle" label="标题" min-width="100px"></el-table-column>
                <el-table-column prop="jaSource" label="来源" min-width="100px"></el-table-column>
                <el-table-column label="封面照片" min-width="100px">
                  <template slot-scope="scope">
                    <img :src="scope.row.jaCoverPicture" style="width: 60px;height: 50px;"/>
                  </template>
                </el-table-column>
                <el-table-column prop="jaHitCount" label="点击量" min-width="100px"></el-table-column>
                <el-table-column prop="suStaffName" label="发布人" min-width="100px"></el-table-column>
                <el-table-column prop="jaPublishTime" label="发布时间" min-width="100px"></el-table-column>
              </el-table>
            </div>
          </el-dialog>
      </el-row><!--功能按钮-->
      <el-row style="padding: 5px 5px 5px 0;">
        标题搜索：<el-input @change="titleSearch" v-model="consulting.titleSearch" size="mini" clearable style="width:15%"></el-input>
        资讯类型：<el-cascader
                              v-model="value12"
                              size="mini"
                              :options="options"
                              @change="insSearch"
                              clearable
                              :props="{ expandTrigger: 'hover' }"></el-cascader>&nbsp;&nbsp;
        发布时间：<el-date-picker
              v-model="consulting.time"
              style="width: 380px;"
              @change="timeSearch"
              unlink-panels
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              size="mini"></el-date-picker>
      </el-row>
    </div>
    <!-- 内容表格 -->
    <div>
      <el-table
        v-loading="loading"
        :data="dataList"
        highlight-current-row
        @current-change="currentChangeData"
        @row-dblclick="dbClickData"
        @select="deleteBatch"
        @select-all="deleteBatchAll"
        :height='tableHeight'
        style="width:100%;">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="jatArticleCategory" label="资讯类型" min-width="130px"></el-table-column>
        <el-table-column prop="jaTitle" label="标题" min-width="220px"></el-table-column>
        <el-table-column prop="jaSource" label="来源" min-width="100px"></el-table-column>
        <el-table-column label="封面照片" min-width="100px">
          <template slot-scope="scope">
            <img :src="scope.row.jaCoverPicture" style="width: 60px;height: 50px;"/>
          </template>
        </el-table-column>
        <el-table-column prop="jaHitCount" label="点击量" min-width="90px"></el-table-column>
        <el-table-column prop="suStaffName" label="发布人" min-width="90px"></el-table-column>
        <el-table-column prop="jaPublishTime" label="发布时间" min-width="110px"></el-table-column>
      </el-table>
      <el-pagination
              background layout="total, sizes, prev, pager, next, jumper"
              align="center"
              :page-count="pages"
              :page-size="size"
              :total="total"
              :current-page="current"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              :page-sizes="[5,10,20,40]"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import VMessage from './components/message'
  import * as Quill from 'quill'
  import resizeImage from 'quill-image-resize-module' // 调整大小组件。
  Quill.register('modules/resizeImage ', resizeImage )
  import { dateFtt } from "../../assets/utils";
  import QuillShow from './components/quillShow'
  export default {
    name:'schoolCounseling',
    data(){
      return{
        zixunTitle: '',
        tableHeight: window.innerHeight - 225,
        recycle: false,
        ifPutIns: false,
        zixunleixinAdd: false,
        addType: '',
        addCategory: '',
        zixunleixin: false,
        saveState: false,
        dialogImageUrl: '',     //选中的某张图片的 url
        dialogVisible: false,    //图片原图展示框
        picData:{             //上传图片携带的参数
          token : "",
        },
        uploadQiniuUrl:"https://upload-z2.qiniup.com",   //七牛云服务器地址
        pic:[],
        loading: false,
        title: '',
        tianjiazixun: false,
        checkInformation: false,

        consulting:{
          titleSearch: '',
          consultingType: '酒店资讯',
          time: [],
        },

        addInformation:{
          type: '',
          type2: '',
          source: '',
          time: dateFtt('yyyy-MM-dd',new Date()),
          theTitle: '',
          views: 0,
          content: '',
          photo: ''
        },
        photoModify: '',
        //富文本框
        // content: `<p></p>`,
        // editorOption: {
        //   theme:'snow'
        // },
        content: '',
      editorOption: {
        modules: {
        imageResize: {
          displayStyles: {
            backgroundColor: "black",
            border: "none",
            color: "white"
          },
          modules: [ 'Resize', 'DisplaySize'] 
        },
        toolbar: [
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['link', 'image'],  //链接与图片
          [{ 'color': [] }, { 'background': [] }], //颜色和背景色
          ["bold", "italic", "underline", "strike"],       // 加粗 斜体 下划线 删除线
          [{ indent: "-1" }, { indent: "+1" }],            // 缩进
          [{ size: ["small", false, "large", "huge"] }],   // 字体大小
          [{ header: [1, 2, 3, 4, 5, 6] }],         // 标题
          [{ align: [] }],                                 // 对齐方式
          ["clean"],                                       // 清除文本格式
        ],
          history: {
          delay: 1000,
          maxStack: 50,
          userOnly: false
        },
        }
        },

        total: 0,         //总条数
        size: 10,        //每页的条数
        pages: 0,      //每页数据
        current: 0,    //初始页

        dataList: [],
        dataList2: [],
        informationList: [],
        value10: [],
        value12: [],
        options: [],
        rowData: '',
        rowDelete: [],
        rowIns: '',
      }
    },
    computed: {
      ...mapState(['authe']),
      editor() {
        return this.$refs.myQuillEditor.quill;
      },
    },
    created() {
      this.dataGet()
      // this.beforeUpload()
      this.informationType()
    },
    methods: {
      dataGet(){
        if(this.consulting.time == null){
          this.consulting.time = []
          this.consulting.time[0] = ''
          this.consulting.time[1] = ''
        }
        this.$axios({
          method: 'get',
          url: '/common1/articles',
          params: {
            jaState: '正常',
            jatSourceType: this.consulting.consultingType,
            cursor: this.cursor,
            limit: this.limit,
            jaTitle: this.consulting.titleSearch,
            jatId: this.value12[1],
            jaStartPublishTime: this.consulting.time[0],
            jaEndPublishTime: this.consulting.time[1]
          }
        }).then(res => {
          console.log(res)
          for(let i in res.data.result.records){
            if(res.data.result.records[i].jaCoverPicture != ''){
              res.data.result.records[i].jaCoverPicture = JSON.parse(res.data.result.records[i].jaCoverPicture)[0].url
            }
          }
          this.dataList = res.data.result.records
          for(let i = 0; i < this.dataList.length; i++){
            this.dataList[i].jatArticleCategory = `${this.dataList[i].jatArticleCategory}/${this.dataList[i].jatArticleType}`
          }
          this.total=res.data.result.total;
          this.size=res.data.result.size;
          this.pages=res.data.result.pages;
          this.current=res.data.result.current;
        }).catch(err => {
          this.isError(err,this)
        })
      },
      tianjiazixunButton(){ //添加资讯
        this.tianjiazixun = true
        this.saveState = false
        this.title = '添加资讯'
        this.informationType()
      },
      xiugaizixunButton(){ //修改资讯
        if(this.rowData == '' || this.rowData == null){
          this.$message.warning('请选择一条资讯')
        }else{
          console.log(this.rowData)
          this.modifyData()
        }
      },
      modifyData(){
        this.tianjiazixun = true
        this.title = '修改资讯'
        this.saveState = true
        this.informationType()
        this.$nextTick(()=> {
          console.log(this.$refs.message)
          this.$refs.message.edit(this.rowData.jaId)
        })
        // this.$axios({
        //   method: 'get',
        //   url: `/common1/articles/${this.rowData.jaId}`,
        // }).then(res => {
        //   let assignment = res.data.result
        //   if(res.data.result.jaCoverPicture[0] != undefined){
        //     res.data.result.jaCoverPicture = JSON.parse(res.data.result.jaCoverPicture)[0].url
        //   }
        //   if(res.data.result.jaCoverPicture != ''){
        //     this.pic = [{name: 'pic.png', url: assignment.jaCoverPicture}]
        //   }
        //   this.value10 = assignment.jatId
        //   this.addInformation.source = assignment.jaSource
        //   this.addInformation.time = assignment.jaPublishTime
        //   this.addInformation.theTitle = assignment.jaTitle
        //   this.addInformation.views = assignment.jaHitCount
        //   this.addInformation.content = assignment.jaSketch
        //   this.content = assignment.jaContent
        //   this.addInformation.photo = assignment.jaCoverPicture
        // }).catch(err => {
        //   this.isError(err,this)
        // })
      },
      dbClickData(){
        console.log('资讯详情');
        this.$axios({
          method: 'get',
          url: `/common1/articles/${this.rowData.jaId}`,
        }).then(res => {
          this.checkInformation = true
          let assignment = res.data.result
          if(res.data.result.jaCoverPicture[0] != undefined){
            res.data.result.jaCoverPicture = JSON.parse(res.data.result.jaCoverPicture)[0].url
          }
          if(res.data.result.jaCoverPicture != ''){
            this.pic = [{name: 'pic.png', url: assignment.jaCoverPicture}]
          }
          this.value10 = assignment.jatName
          this.addInformation.source = assignment.jaSource
          this.addInformation.time = assignment.jaPublishTime
          this.addInformation.theTitle = assignment.jaTitle
          this.addInformation.views = assignment.jaHitCount
          this.addInformation.content = assignment.jaSketch
          this.content = assignment.jaContent
          this.addInformation.photo = assignment.jaCoverPicture
        }).catch(err => {
          this.isError(err,this)
        })
      },
      tianjiazixunPut(){//修改保存
        console.log(this.addInformation.photo)
        this.loading = true
        this.tianjiazixun = false
        let value11 = 0
        if(this.value10[1] == undefined){
          value11 = this.value10
        }else{
          value11 = this.value10[1]
        }
        if(this.addInformation.photo == ''){
          this.photoModify = ''
        }else{
          this.photoModify = JSON.stringify([{url:this.addInformation.photo}])
        }
        this.$axios({
          method: 'put',
          url: `/common1/articles/${this.rowData.jaId}`,
          data: {
            jatId: value11,
            jaSource: this.addInformation.source,
            jaPublishTime: this.addInformation.time,
            jaTitle: this.addInformation.theTitle,
            jaHitCount: this.addInformation.views,
            jaCoverPicture: this.photoModify,
            jaSketch: this.addInformation.content,
            jaContent: this.content
          }
        }).then(res => {
          this.$message.success('修改成功')
          this.dataGet()
          this.loading = false
        }).catch(err => {
          this.loading = false
          this.isError(err,this)
        })
      },
      informationType(){
        this.$axios({
          method: 'get',
          url: '/common1/articleTypes/articleTypes',
          params: {
            jatSourceType: '酒店资讯'
          }
        }).then(res => {
          for(let i = 0; i < res.data.result.length; i++){
            this.options[i] = {}
            this.options[i].value=res.data.result[i].jatArticleCategory
            this.options[i].label=res.data.result[i].jatArticleCategory
            this.options[i].children = [];
          }
          //一级去重
          let brandsListArr = [];
          let brandsListObj = {};
          for ( let i = 0;i < this.options.length ; i++) {
            if (!brandsListObj[this.options[i].label]) {
              brandsListArr.push(this.options[i]);
              brandsListObj[this.options[i].label] = true;
            }
          }
          this.options = brandsListArr;
          //获取二级数据
          for (let i = 0; i < this.options.length ; i++) {
            for ( let j = 0 ; j < res.data.result.length ; j++) {
              if ( this.options[i].value === res.data.result[j].jatArticleCategory){
                let brandsLi= {};
                brandsLi.value = res.data.result[j].jatId;
                brandsLi.label = res.data.result[j].jatArticleType;
                this.options[i].children.push(brandsLi);
              }
            }
          }
          console.log(this.options)
        }).catch(err => {
          this.isError(err,this)
        })
      },
      cleanDialogAdd(){//添加资讯弹框清空
        this.addInformation = {
            type: '',
            type2: '',
            source: '',
            time: dateFtt('yyyy-MM-dd',new Date()),
            theTitle: '',
            views: 0,
            content: '',
            photo: ''
        }
        this.value10 = ''
        this.content = ''
        this.pic = []
        this.dataGet()
        this.rowData =  ''
      },
      handleCurrentChange(cursor) {
        this.cursor = cursor
        this.dataGet()
      },
      handleSizeChange(limit){
        this.limit = limit
        this.dataGet()
      },
      // 准备富文本编辑器
      // onEditorReady(editor) {},
      //   show() {
      //     this.$nextTick(() => {
      //       this.$refs.message.quill
      //         .getModule("toolbar")
      //         .addHandler("image", this.imgHandler);
      //     });
      // },
      success(res, file,fileList){//富文本图片上传成功
        //图片的远程路径
        let value = 'https://pic-gongkai.fangzhizun.com/'+res.key;
        // 将图片添加到富文本内容区域
        this.addRange = this.$refs.myQuillEditor.quill.getSelection();
        // 调用编辑器的 insertEmbed 方法，插入URL
        this.$refs.myQuillEditor.quill.insertEmbed(this.addRange !== null ? this.addRange.index : 0, 'image', value, Quill.sources.USER)
      },
      imgHandler(state) {// 点击图片ICON触发事件
        this.addRange = this.$refs.myQuillEditor.quill.getSelection()
        if (state) {
          // 选择并上传一张图片
          let fileInput = document.getElementById('imgInput')
          fileInput.click() // 加一个触发事件
        }
      },
      saveHtml:function(event){
        alert(this.content);
      },
      // tianjiazixunGetPhotos(random){
      //   this.$axios({
      //     method: 'get',
      //     url: '/common1/photos',
      //     params: {
      //       photoRandom: random,
      //       photoTableType: '资讯'
      //     }
      //   }).then(res => {
      //     console.log(res)
      //   }).catch(err => {
      //     this.isError(err,this)
      //   })
      // },
      // tianjiazixunPhotos(){//上传图片到本地库
      //   let random = Math.ceil(Math.random()*1000).toString()
      //   let photoList = [
      //     {
      //       photoBucket: "公有",
      //       photoMimeType: "图片",
      //       photoRandom: random,
      //       photoStatus: "正常",
      //       photoTableType: "资讯",
      //       photoUrl: this.addInformation.photo
      //     }
      //   ]
      //   this.$axios({
      //     method: 'post',
      //     url: '/common1/photos',
      //     data: JSON.stringify(photoList)
      //   }).then(res => {
      //     this.tianjiazixunGetPhotos(random)
      //   }).catch(err => {
      //     this.isError(err,this)
      //   })
      // },
      tianjiazixunSave(){
        this.loading = true,
        this.$axios({
          method: 'post',
          url: '/common1/articles',
          data: {
            jatId: this.value10[1],
            jaSource: this.addInformation.source,
            jaPublishTime: this.addInformation.time,
            jaTitle: this.addInformation.theTitle,
            jaHitCount: this.addInformation.views,
            jaCoverPicture: JSON.stringify([{url:this.addInformation.photo}]),
            jaSketch: this.addInformation.content,
            jaContent: this.content
          }
        }).then(res => {
          this.$message.success('添加成功')
          // this.tianjiazixunPhotos()
          this.dataGet()
          this.loading = false
          this.tianjiazixun = false
        }).catch(err => {
          this.loading = false
          this.tianjiazixun = false
          this.isError(err,this)
        })
      },
      //获取token
      getToken(){
        return this.$axios({
          method: 'get',
          url: '/common1/photos/token',
          params: {
            bucket: '公有'
          }
        }).then(res => {
          return res.data.result;
        }).catch(err => {
          this.isError(err,this)
        })
      },
      async beforeUpload(){//上传完文件前获取token
        try {
          let getToken = await this.getToken();
          this.picData.token = getToken;
        } catch (err) {
          console.log(err);
        }
      },
      handleAvatarSuccess(res, file,fileList) {//上传成功后在图片框显示图片
        this.dialogImageUrl ='https://pic-gongkai.fangzhizun.com/'+ res.key
        file.url = this.dialogImageUrl
        this.addInformation.photo = file.url
        this.pic=fileList
      },
      handleError(res) {//显示错误
        console.log(res)
      },
      handlePictureCardPreview(file) {//查看某张图片的原图
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      beforeRemove(file) {//删除前
        return this.$confirm(`确定移除？`);
      },
      handleRemove(file,fileList){//删除后
        this.pic=fileList
        this.addInformation.photo = ''
      },
      currentChangeData(row){//当表改变时
        this.rowData = row;
      },
      deleteBatch(row){
        this.rowDelete = row
        console.log(this.rowDelete)
      },
      deleteBatchAll(selection){//回收站全选
        this.rowDelete = selection
      },
      consultingDelete(){//改变咨询的状态
        if(this.rowDelete == ''){
          this.$message.warning('请选择至少一条咨询')
        }else{
          this.loading = true
          let deleteId = []
          for(let i = 0; i < this.rowDelete.length; i++){
            deleteId[i] = this.rowDelete[i].jaId
          }
          this.$axios({
            method: 'post',
            url: '/common1/articles/delete',
            data: {
              jaIds: deleteId.map(Number)
            }
          }).then(res => {
            this.$message.success('删除成功')
            this.dataGet()
            this.loading = false
            this.rowDelete = ''
          }).catch(err => {
            this.rowDelete = ''
            this.loading = false
            this.isError(err,this)
          })
        }
      },
      zixunleixinGet(){
        this.$axios({
          method: 'get',
          url: '/common1/articleTypes/articleTypes',
          params: {
            jatSourceType: '酒店资讯'
          }
        }).then(res => {
          this.informationList = res.data.result
          this.loading = false
          console.log(this.informationList)
        }).catch(err => {
          this.loading = false
          this.isError(err,this)
        })
      },
      zixunleixinButton(){//添加资讯类型
        this.zixunleixin = true
        this.loading = true
        this.zixunleixinGet()
      },
      zixunleixinAddSave(){//添加资讯请求
        if(this.addType == '' || this.addCategory == ''){
          this.$message.warning('内容不能为空')
        }else{
          this.loading = true
          this.$axios({
            method: 'post',
            url: '/common1/articleTypes',
            data: {
              jatSourceType: "酒店资讯",
              jatArticleCategory: this.addType,
              jatArticleType: this.addCategory
            }
          }).then(res => {
            console.log(res)
            this.$message.success('添加成功')
            this.zixunleixinGet()
            this.loading = false
            this.zixunleixinAdd = false
          }).catch(err => {
            this.loading = false
            this.zixunleixinAdd = false
            this.isError(err,this)
          })
        }
      },
      currentInfData(row){
        this.rowIns = row
      },
      dbClickInf(){//双击弹出框
        this.zixunleixinAdd = true
        this.ifPutIns = true
        this.zixunTitle = '修改资讯类型'
        this.addType = this.rowIns.jatArticleCategory
        this.addCategory = this.rowIns.jatArticleType
      },
      zixunleixinAddClick(){
        this.zixunleixinAdd = true
        this.zixunTitle = '添加资讯类型'
        console.log(this.zixunleixinAdd)
      },
      zixunleixinAlter(){//修改咨询类型
        if(!this.rowIns){
          this.$message.warning('请选择一条类型')
        }else{
          this.dbClickInf()
        }
      },
      zixunleixinAddPut(){//修改咨询类型请求
        if(this.addType == '' || this.addCategory == ''){
          this.$message.warning('内容不能为空')
        }else{
          this.loading = true
          this.$axios({
            method: 'put',
            url: `/common1/articleTypes/${this.rowIns.jatId}`,
            data: {
              jatArticleCategory: this.addType,
              jatArticleType: this.addCategory
            }
          }).then(res => {
            console.log(res)
            this.$message.success('修改成功')
            this.zixunleixinGet()
            this.loading = false
            this.zixunleixinAdd = false
          }).catch(err => {
            this.loading = false
            this.zixunleixinAdd = false
            this.isError(err,this)
          })
        }
      },
      deleteIns(){//删除咨询类型
        if(!this.rowIns){
          this.$message.warning('请选择一条内容')
        }else{
          this.loading = true
          this.$axios({
            method: 'delete',
            url: `/common1/articleTypes/${this.rowIns.jatId}`,
          }).then(res => {
            this.$message.error('删除成功')
            this.rowIns = '';
            this.zixunleixinGet()
            this.loading = false
          }).catch(err => {
            this.loading = false
            this.isError(err,this)
          })
        }
      },
      cleanDialogAddIns(){//添加资讯清空
        this.addType = ''
        this.addCategory = ''
        this.ifPutIns = false
      },
      titleSearch(){//标题搜索
        this.dataGet()
      },
      insSearch(){
        this.dataGet()
      },
      timeSearch(){
        this.dataGet()
      },
      recycleButton(){
        this.recycle = true
        this.$axios({
          method: 'get',
          url: '/common1/articles',
          params: {
            jaState: '注销',
            jatSourceType: this.consulting.consultingType,
          }
        }).then(res => {
          for(let i in res.data.result.records){
            if(res.data.result.records[i].jaCoverPicture != ''){
              res.data.result.records[i].jaCoverPicture = JSON.parse(res.data.result.records[i].jaCoverPicture)[0].url
            }
          }
          this.dataList2 = res.data.result.records
          for(let i = 0; i < this.dataList2.length; i++){
            this.dataList2[i].jatArticleCategory = `${this.dataList2[i].jatArticleCategory}/${this.dataList2[i].jatArticleType}`
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
      reduction(){
        if(this.rowDelete == ''){
          this.$message.warning('勾选至少一条资讯')
        }else{
          console.log(this.rowDelete)
          let jaIds = []
          this.rowDelete.map(item => {
            jaIds.push(item.jaId)
          })
          console.log(jaIds)
          this.$axios({
            method:'post',
            url:'/common1/articles/reductions',
            data:{
              jaIds:jaIds
            }
          }).then(res => {
            console.log(res)
            this.$message.success('还原成功')
            this.recycleButton()
            this.dataGet()
            this.loading = false
            this.rowDelete = ''
          }).catch(err => {
            this.loading = false
            this.rowDelete = ''
            this.isError(err,this)
          })
          // for(let i = 0; i < this.rowDelete.length; i++){
          //   this.$axios({
          //     method: 'put',
          //     url: `/common1/articles/${this.rowDelete[i].jaId}`,
          //     data: {
          //       jaState: '正常',
          //       jatId: this.rowDelete[i].jatId
          //     }
          //   }).then(res => {
          //     console.log(res)
          //     this.$message.success('还原成功')
          //     this.recycleButton()
          //     this.dataGet()
          //     this.loading = false
          //     this.rowDelete = ''
          //   }).catch(err => {
          //     this.loading = false
          //     this.rowDelete = ''
          //     this.isError(err,this)
          //   })
          // }
          this.dataGet()
        }
      }
    },
    components: {
      VMessage,
      QuillShow
    }
  }
</script>
<style lang="scss" scoped>
  >>> .ql-editor{
    height: 150px;
  }
</style>
